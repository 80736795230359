const Checkbox = {
  parts: ["icon", "control", "label"],
  baseStyle: {
    control: {
      _checked: {
        background: "bluefrance.500",
        borderColor: "bluefrance.500",
      },
    },
  },
}

export { Checkbox }
