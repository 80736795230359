const Alert = {
  variants: {
    akto: {
      container: {
        bg: "#00a79b",
        borderTopWidth: "3px",
        borderTopColor: "#1d4851",
      },
      description: {
        color: "#fff",
      },
      icon: {
        color: "#fff",
      },
    },
    "top-accent": {
      container: {
        bg: "bluefrance.200",
        borderTopColor: "bluefrance.500",
      },
      icon: {
        color: "bluefrance.500",
      },
    },
  },
}

export { Alert }
